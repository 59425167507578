
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { lastValueFrom, throwError } from 'rxjs'
import { AppsettingsService } from '../../app-settings/app-settings.service'
import { GlobalService } from '../../global/global.service'
import { JwtService } from '../../jwt/jwt.service'

// STAFF
export const ROLE_SYSTEM_ADMIN = 1;
export const ROLE_CUSTOMER_ADMIN = 2;
export const ROLE_SALES_MANAGER = 3;
export const ROLE_SALES_STAFF = 4;

export const ROLE_GROUP_AL_CUSTOMER_ADMIN   = [ROLE_SALES_MANAGER, ROLE_CUSTOMER_ADMIN]
export const ROLE_GROUP_AL_SALES_MANAGER    = [ROLE_SALES_MANAGER, ROLE_CUSTOMER_ADMIN, ROLE_SYSTEM_ADMIN]
export const ROLE_GROUP_AL_SALES_STAFF      = [ROLE_SALES_MANAGER, ROLE_CUSTOMER_ADMIN, ROLE_SYSTEM_ADMIN, ROLE_SALES_STAFF]

// INTERFACES
import * as int from 'src/app/core/interfaces/users/users.interface'

interface GetUsers {
	sortByDate: string;
	page: number;
	searchInput: string;
	groupId: number;
	filterByUserId: number;
	filterByEmptyField: string;
	filterByActive: number;
	sortField?: string;
	sortDirection?: number;
}

interface Search {
	searchInput: string;
    limit?: number;
}

interface UserId {
    userId: number;
}

interface UpdateUserProfile {
    firstName: string;
    lastName: string;
    userId:number;
    profileColor: string;
}

@Injectable({
    providedIn: 'root'
})
export class UsersService {

    constructor(
        private HttpClient: HttpClient,
        private AppsettingsService: AppsettingsService,
        private JwtService: JwtService,
        private GlobalService: GlobalService
    ) { }

    currentUserId:any = false;

    getCurrentUserId() {

        if ( this.currentUserId != false ) {
            return this.currentUserId;
        }

        let decodedToken = this.JwtService.getDecodedToken();

        if ( !decodedToken ) {
            return false;
        }
        
        let userId = decodedToken.userId;

        if ( userId === undefined || userId == false ) {
            return false;
        }

        this.currentUserId = userId;
        return userId;
    }

    currentUser:any = false;

    initialize() {
        return new Promise( async (resolve, reject) => {
            this.currentUser = await this.getCurrentUser().catch( () => {})

            if ( this.currentUser == false ) {
                return resolve(true);
            }

            const firstInitials = this.currentUser.firstName.toUpperCase().substring(0, 2);
            const lastInitials = this.currentUser.lastName.toUpperCase().substring(0, 2);

            this.currentUser.initials = firstInitials + lastInitials;

            this.getCurrentUserDisplayName();

            return resolve(true);
        })
    }

    currentUserDisplayName:any = false;

    getCurrentUserDisplayName() {

        return new Promise( async (resolve, reject) => {

            if ( this.currentUser == false ) {
                await this.initialize();
            }

            if ( this.currentUser.displayName == false ) {
                return resolve(false);
            }

            return resolve(this.currentUser.displayName);

        });

    }

    getCurrentUserInitials() {

        return new Promise( async (resolve, reject) => {

            if ( this.currentUser == false ) {
                await this.initialize();
            }

            if ( this.currentUser.initials == false ) {
                return resolve(false);
            }

            return resolve(this.currentUser.initials);

        });

    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
        }
        // Return an observable with a user-facing error message.
        return throwError(
            'Something bad happened; please try again later.');
    }

    isAdmin() {

        if ( this.getCurrentUserId() == 1 || this.getCurrentUserId() == 2 ) {
            return true;
        
        } else {
            return false;
        }
        
    }

    getUsersForUserSelect() {
        return new Promise( (resolve, reject) => {
            let url = this.AppsettingsService.getApiServerUrl() + 'users/all/select';
            this.HttpClient.post(url, {}).subscribe( (res:any) => {
                return resolve( this.GlobalService.httpCheckReturn(res) );
            });
        });
    }

    getUsersOld() {
        return new Promise( (resolve, reject) => {
            let url = this.AppsettingsService.getApiServerUrl() + 'users/all';
            this.HttpClient.post(url, {}).subscribe( (res:any) => {
                return resolve( this.GlobalService.httpCheckReturn(res) );
            });
        });
    }

    updateUserRoleId( roleId:number, userId:number ) {
        return new Promise( (resolve, reject) => {
            let url = this.AppsettingsService.getApiServerUrl() + 'users/user/update/role';
            this.HttpClient.post(url, {roleId: roleId, userId: userId}).subscribe( (res:any) => {
                return resolve( this.GlobalService.httpCheckReturn(res) );
            });
        });
    }

    updateUserEmailAddress( emailAddress:number, userId:number ) {
        return new Promise( (resolve, reject) => {
            let url = this.AppsettingsService.getApiServerUrl() + 'users/user/update/email-address';
            this.HttpClient.post(url, {emailAddress: emailAddress, userId: userId}).subscribe( (res:any) => {
                return resolve( this.GlobalService.httpCheckReturn(res) );
            });
        });
    }

    updateUserSlackMemberID( slackMemberID:number, userId:number ) {
        return new Promise( (resolve, reject) => {
            let url = this.AppsettingsService.getApiServerUrl() + 'users/user/update/slack-member-id';
            this.HttpClient.post(url, {slackMemberID: slackMemberID, userId: userId}).subscribe( (res:any) => {
                return resolve( this.GlobalService.httpCheckReturn(res) );
            });
        });
    }

    updateActive( active:number, userId:number ) {
        return new Promise( (resolve, reject) => {
            let url = this.AppsettingsService.getApiServerUrl() + 'users/user/update/active';
            this.HttpClient.post(url, {active: active, userId: userId}).subscribe( (res:any) => {
                return resolve( this.GlobalService.httpCheckReturn(res) );
            });
        });
    }

    deleteUser( userId:number ) {
        return new Promise( (resolve, reject) => {
            let url = this.AppsettingsService.getApiServerUrl() + 'users/user/delete';
            this.HttpClient.post(url, {userId: userId}).subscribe( (res:any) => {
                return resolve( this.GlobalService.httpCheckReturn(res) );
            });
        });
    }

    createUser( firstName:string, lastName:string, emailAddress:string, roleId:number, password?:string ) {
        return new Promise( (resolve, reject) => {
            let url = this.AppsettingsService.getApiServerUrl() + 'users/create';
            this.HttpClient.post(url, {
                firstName: firstName,
                lastName: lastName,
                emailAddress: emailAddress,
                roleId: roleId,
                password: password
            }).subscribe( (res:any) => {
                return resolve( this.GlobalService.httpCheckReturn(res) );
            });
        });
    }
    
    getUsersByRoleId( roleIds?:number | Array<number> ) {
        return new Promise( (resolve, reject) => {
            let url = this.AppsettingsService.getApiServerUrl() + 'users/get-users-by-role-id';
            this.HttpClient.post(url, {roleIds: roleIds}).subscribe( (res:any) => {
                return resolve( this.GlobalService.httpCheckReturn(res) );
            });
        });
    }
    httpOptions = {
        withCredentials: true //this is required so that Angular returns the Cookies received from the server. The server sends cookies in Set-Cookie header. Without this, Angular will ignore the Set-Cookie header
    };
    getCurrentUser(): Promise<int.User> {
        return new Promise((resolve, reject) => {
            const url = this.AppsettingsService.getApiServerUrlBase() + `users/current-user`;
            this.HttpClient.get(url, this.httpOptions ).subscribe({
                next: (data: any) => resolve(data),
                error: (err: HttpErrorResponse) => reject(err),
            });
        });
    }

    getUsersCounters() {
        return new Promise( (resolve, reject) => {
            let url = this.AppsettingsService.getApiServerUrl() + 'users/counters';
            this.HttpClient.post(url, {}).subscribe( (res:any) => {
                return resolve( this.GlobalService.httpCheckReturn(res) );
            });
        });
    }

    updateUserProfile( data:UpdateUserProfile ) {
        return new Promise( (resolve, reject) => {
            let url = this.AppsettingsService.getApiServerUrl() + 'users/user/update/profile';
            this.HttpClient.post(url, {
                firstName: data.firstName,
                lastName: data.lastName,
                userId: data.userId,
                profileColor: data.profileColor
            }).subscribe( (res:any) => {
                return resolve( this.GlobalService.httpCheckReturn(res) );
            });
        });
    }

    getUsersFilters( filters:Array<object>, pageNumber:number ) {
		return new Promise( (resolve, reject) => {
			let url = this.AppsettingsService.getApiServerUrl() + 'users/search/filters';
				this.HttpClient.post(url, { filters: filters, pageNumber: pageNumber }).subscribe( (res:any) => {
                    console.log(res)
				return resolve( this.GlobalService.httpCheckReturn(res) );
			});
		});
	}

    search( data:Search ): Promise<int.User[]> {
        return new Promise((resolve, reject) => {
            const url = this.AppsettingsService.getApiServerUrl() + `users/search`;
            this.HttpClient.get(url, {
				params: { 
                    searchInput: data.searchInput
                }
			}).subscribe({
                next: (data: any) => resolve(data),
                error: (err: HttpErrorResponse) => reject(err),
            });
        });
    }

	searchDefaults(): Promise<int.User[]> {
        return new Promise((resolve, reject) => {
            const url = this.AppsettingsService.getApiServerUrl() + `users/search/default`;
            this.HttpClient.get(url).subscribe({
                next: (data: any) => resolve(data),
                error: (err: HttpErrorResponse) => reject(err),
            });
        });
    }

    getUserForSearch( data:UserId ): Promise<int.User> {
        return new Promise((resolve, reject) => {
            const url = this.AppsettingsService.getApiServerUrl() + `users/search/user`;
            this.HttpClient.get(url, {
				params: { userId: data.userId }
			}).subscribe({
                next: (data: any) => resolve(data),
                error: (err: HttpErrorResponse) => reject(err),
            });
        });
    }

    getUsers( data:GetUsers ): Promise<any> {
        return new Promise((resolve, reject) => {

			const url = this.AppsettingsService.getApiServerUrl() + `users`;

			let params:any = { params: {} };

			const entries = Object.entries(data);
			for (const [key, value] of entries) {
				if ( value === undefined ) continue;
				
				// Convert bool to number
				if ( value === true ) {
					params.params[key] = 1
					
				} else if ( value === false ) {
					params.params[key] = 0
					
				} else {
					params.params[key] = value
				}
			}
			
			this.HttpClient.get(url, params).subscribe({
				next: (data: any) => resolve(data),
				error: (err: HttpErrorResponse) => reject(err),
			}); 

        });
    }

	getUsersGroupCount( data:GetUsers ): Promise<any> {
        return new Promise((resolve, reject) => {

			const url = this.AppsettingsService.getApiServerUrl() + `users/group-counts/`;

			let params:any = { params: {} };

			const entries = Object.entries(data)
			for ( const [key, value] of entries ) {
				if ( value === undefined ) continue;
				
				// Convert bool to number
				if ( value === true ) {
					params.params[key] = 1
					
				} else if ( value === false ) {
					params.params[key] = 0
					
				} else {
					params.params[key] = value
				}
			}
			
			this.HttpClient.get(url, params).subscribe({
				next: (data: any) => resolve(data),
				error: (err: HttpErrorResponse) => reject(err),
			}); 

        });
    }

    async getUser( userId:number ):Promise<int.User> {
        const url = `${this.AppsettingsService.getApiServerUrlNew()}users/single`
        return await lastValueFrom(this.HttpClient.get<int.User>( url, { params: { userId: userId } }))
    }

    async getUsersByCustomer( customerId:number ):Promise<int.GetUsers> {

        const url = `${this.AppsettingsService.getApiServerUrlNew()}users`

        return await lastValueFrom(this.HttpClient.get<int.GetUsers>( url, { 
            params: { connectedCustomerId: customerId, pagination:false } 
        }))
    }

    async getUserInvitesByCustomer( customerId:number ):Promise<int.UsersInvite[]> {

        const url = `${this.AppsettingsService.getApiServerUrlNew()}users/invites`

        return await lastValueFrom(this.HttpClient.get<int.UsersInvite[]>( url, { 
            params: { customerId: customerId, pagination:false } 
        }))
    }

    async inviteUserToCustomer( customerId:number, emailAddress:string ):Promise<number> {

        const url = `${this.AppsettingsService.getApiServerUrlNew()}users/invites`

        return await lastValueFrom(this.HttpClient.post<number>( url, {customerId: customerId, emailAddress: emailAddress} ))
    }
    
    async updatePasswordForUser( password:number, userId:number ):Promise<boolean> {
        const url = `${this.AppsettingsService.getApiServerUrl()}users/password/update`
        return await lastValueFrom(this.HttpClient.post<boolean>( url, { password: password, userId: userId }))
    }

    async removeUserInvite( emailAddress:string ):Promise<boolean> {
        const url = `${this.AppsettingsService.getApiServerUrlNew()}users/invites`
        return await lastValueFrom(this.HttpClient.delete<boolean>( url, { params: { emailAddress: emailAddress }  }))
    }

    async refreshUserInvite( inviteId:number ):Promise<boolean> {
        const url = `${this.AppsettingsService.getApiServerUrlNew()}users/invites/refresh`
        return await lastValueFrom(this.HttpClient.patch<boolean>( url, {id: inviteId}  ))
    } 

    async getUserSalesTopPrivate( filters:int.GetStatistics ):Promise<int.CurrentPosition> {

        let params = new HttpParams();

		const entries = Object.entries(filters);
		for (const [key, value] of entries) {
			if (value === undefined) continue;
	
			// Convert bool to number
			if (value === true) {
				params = params.set(key, '1');
			} else if (value === false) {
				params = params.set(key, '0');
			} else {
				params = params.set(key, value.toString());
			}
		}
        
        const url = `${this.AppsettingsService.getApiServerUrlNew()}users/statistics/current`
        return await lastValueFrom(this.HttpClient.get<int.CurrentPosition>( url, { params }  ))
    }  

    async getUserSalesTopPublic( filters:int.GetStatistics ):Promise<int.GetStatisticsR> {

        let params = new HttpParams();

		const entries = Object.entries(filters);
		for (const [key, value] of entries) {
			if (value === undefined) continue;
	
			// Convert bool to number
			if (value === true) {
				params = params.set(key, '1');
			} else if (value === false) {
				params = params.set(key, '0');
			} else {
				params = params.set(key, value.toString());
			}
		}
        
        const url = `${this.AppsettingsService.getApiServerUrlNew()}users/statistics/`
        return await lastValueFrom(this.HttpClient.get<int.GetStatisticsR>( url, { params }  ))
    }  

}