
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppsettingsService } from '../app-settings/app-settings.service';
import { GlobalService } from '../global/global.service';
import { InvoiceAddress } from '../../interfaces/customers/addresses/invoice-address';
import { SalesOrder } from '../../interfaces/sales-orders';
import { ShippingOption } from '../../interfaces/shipping-option';
import { DeliveryAddress } from '../../interfaces/customers/addresses/delivery-address';
import { SalesOrderPersonalStats } from '../../interfaces/sales-order-personal-stats';
import { Customer } from '../../interfaces/customers/customers.interface';


export interface UpdateProduct {
    salesOrderId: number;
    orderProductRowId: number,
    customerPrice?: number | null;
    price?: number;
    purchasePrice?:number;
    quantity?: number;
    discountTypeId?: number;
    discountAmount?: number | null;
    discountPercentage?: number | null;
}

interface CustomerId {
    customerId: number;
}

interface salesOrderId {
    salesOrderId: number;
}

interface CustomerId {
    customerId: number;
}

export interface UpdateSalesOrder {
    salesOrderId: number;
    status?: number;
    notes?: string;
    shippingName?: string;
    externalPurchaseNumber?:string;
    customerReference?:string;
    deliveryDate?:string | null;
    orderDate?:string | null;
    paymentTermId?:number;
    customerInvoiceAddressId?: number | null;
    customerDeliveryAddressId?: number | null;
    deviatingDeliveryAddress?: boolean;
    waitForCache?: boolean;
    userId?:number;
    typeId?: number;
}

interface UpdateCustomer {
    salesOrderId: number;
    customerId: number;
}

interface GetSalesOrderInfo {
    userId?: number;
    statusId: number;
}

export interface SalesOrderInfo {
    counts: any;
    currencySymbol: string;
}

interface SalesOrderId {
    salesOrderId: number;
}

interface GetPersonalStats {
    userId?: number;
}

interface GetSalesOrders {
	sortByDate: string;
	page: number;
    statusIds: number[];
    customerId?: number;
	sortField?: string;
	sortDirection?: number;
    filterByUserId?: number;
    invoices?: number;
}

interface GetSalesOrdersGroupCount extends GetSalesOrders {
    countStatusIds?: number[];
}

@Injectable({
    providedIn: 'root'
})
export class SalesOrdersService {

	constructor(
		private AppsettingsService: AppsettingsService,
		private HttpClient: HttpClient,
		private GlobalService: GlobalService
	) { }

    getSalesOrders( data:GetSalesOrders ): Promise<any> {
        return new Promise((resolve, reject) => {

			const url = this.AppsettingsService.getApiServerUrl() + `sales-orders`;

			let params:any = { params: {} };

			const entries = Object.entries(data);
			for (const [key, value] of entries) {
				if ( value === undefined ) continue;
				
				// Convert bool to number
				if ( value === true ) {
					params.params[key] = 1
					
				} else if ( value === false ) {
					params.params[key] = 0
					
				} else {
					params.params[key] = value
				}
			}
			
			this.HttpClient.get(url, params).subscribe({
				next: (data: any) => resolve(data),
				error: (err: HttpErrorResponse) => reject(err),
			}); 

        });
    }

	getSalesOrdersGroupCount( data:GetSalesOrdersGroupCount ): Promise<any> {
        return new Promise((resolve, reject) => {

			const url = this.AppsettingsService.getApiServerUrl() + `sales-orders/group-counts`;

			let params:any = { params: {} };

			const entries = Object.entries(data);
			for (const [key, value] of entries) {
				if ( value === undefined ) continue;
				
				// Convert bool to number
				if ( value === true ) {
					params.params[key] = 1
					
				} else if ( value === false ) {
					params.params[key] = 0
					
				} else {
					params.params[key] = value
				}
			}
			
			this.HttpClient.get(url, params).subscribe({
				next: (data: any) => resolve(data),
				error: (err: HttpErrorResponse) => reject(err),
			}); 

        });
    }

    getSalesOrder( data:SalesOrderId ): Promise<SalesOrder> {
        return new Promise((resolve, reject) => {
            const url = this.AppsettingsService.getApiServerUrl() + `sales-orders/order`;
            this.HttpClient.get(url, {
                params: { salesOrderId: data.salesOrderId }
            }).subscribe({
                next: (data: any) => resolve(data),
                error: (err: HttpErrorResponse) => reject(err),
            });
        });
    }

    getSalesOrderBase( data:SalesOrderId ): Promise<SalesOrder> {
        return new Promise((resolve, reject) => {
            const url = this.AppsettingsService.getApiServerUrl() + `sales-orders/base`;
            this.HttpClient.get(url, {
                params: { salesOrderId: data.salesOrderId }
            }).subscribe({
                next: (data: any) => resolve(data),
                error: (err: HttpErrorResponse) => reject(err),
            });
        });
    }
    
    addProductToOrder( productId:number, orderId:number, quantity:number, customerId: number ) {
        return new Promise<SalesOrder[]>( ( resolve, reject ) => {
            let url = this.AppsettingsService.getApiServerUrl() + 'sales-orders/products/add'
            return this.HttpClient.post( url, {
                orderId: orderId,
                productId: productId,
                quantity: quantity,
                customerId: customerId
            }).subscribe( ( res: any ) => {
                return resolve( this.GlobalService.httpCheckReturnEmptyArray( res ) )
            })
        })
    }
    
    removeProductFromOrder( orderId:number, orderProductRowId:number ) {
        return new Promise<SalesOrder[]>((resolve, reject) => {
            let url = this.AppsettingsService.getApiServerUrl() + 'sales-orders/products/remove';
            return this.HttpClient.post(url, {
                orderId: orderId,
                orderProductRowId: orderProductRowId
            }).subscribe((res: any) => {
                return resolve(this.GlobalService.httpCheckReturnEmptyArray(res));
            });
        });
    }
    
    updateProduct( data: UpdateProduct ) {
        return new Promise((resolve) => {
            let url = this.AppsettingsService.getApiServerUrl() + 'sales-orders/products/product';
            return this.HttpClient.post(url, data).subscribe((res: any) => {
                return resolve(this.GlobalService.httpCheckReturn(res));
            });
        });
    }
    
    updateOrder( data:UpdateSalesOrder ) {
        return new Promise((resolve) => {
            let url = this.AppsettingsService.getApiServerUrl() + 'sales-orders/order';
            return this.HttpClient.patch(url, data).subscribe((res: any) => {
                return resolve(this.GlobalService.httpCheckReturn(res));
            });
        });
    }
    
    deleteOrder( salesOrderId:number ) {
        return new Promise((resolve, reject) => {
            let url = this.AppsettingsService.getApiServerUrl() + 'sales-orders/order/delete';
            return this.HttpClient.post(url, {
                salesOrderId: salesOrderId
            }).subscribe((res: any) => {
                return resolve(this.GlobalService.httpCheckReturn(res));
            });
        });
    }
    
    checkFinalizeOrder( salesOrderId:number ): Promise<Customer> {
        return new Promise((resolve, reject) => {
            const url = this.AppsettingsService.getApiServerUrl() + `sales-orders/order/finalize/${salesOrderId}`;
            this.HttpClient.get(url).subscribe({
                next: (data: any) => resolve(data),
                error: (err: HttpErrorResponse) => reject(err),
            });
        });
    }

    finalizeOrder( data:salesOrderId ): Promise<Customer> {
        return new Promise((resolve, reject) => {
            const url = this.AppsettingsService.getApiServerUrl() + `sales-orders/order/finalize`;
            this.HttpClient.post(url, {
                salesOrderId: data.salesOrderId
            }).subscribe({
                next: (data: any) => resolve(data),
                error: (err: HttpErrorResponse) => reject(err),
            });
        });
    }

    createSalesOrder( customerId:number, status:number, currencyId:number ) {
		return new Promise( (resolve, reject) => {
			let url = this.AppsettingsService.getApiServerUrl() + 'sales-orders/order';
				this.HttpClient.post(url, {
					customerId: customerId,
					status: status,
					currencyId: currencyId
				}).subscribe( (res:any) => {
				return resolve( this.GlobalService.httpCheckReturn(res) );
			});
		});
	}

    getDeliveryAddress( salesOrderId:number ) {
		return new Promise<DeliveryAddress>( (resolve) => {
			let url = this.AppsettingsService.getApiServerUrl() + `sales-orders/order/${salesOrderId}/delivery-address`;
				this.HttpClient.get(url).subscribe( (res:any) => {
				return resolve( this.GlobalService.httpCheckReturn(res) );
			});
		});
	}

    refreshCache( salesOrderId:number ) {
		return new Promise<InvoiceAddress>( (resolve) => {
			let url = this.AppsettingsService.getApiServerUrl() + `sales-orders/order/${salesOrderId}/cache`;
				this.HttpClient.get(url).subscribe( (res:any) => {
				return resolve( this.GlobalService.httpCheckReturn(res) );
			});
		});
	}

    updateShipping( salesOrderId:number, shippingCode:string ) {
		return new Promise<ShippingOption>( (resolve) => {
			let url = this.AppsettingsService.getApiServerUrl() + `sales-orders/order/${salesOrderId}/shipping`;
				this.HttpClient.post(url, {
                    salesOrderId: salesOrderId,
                    shippingCode: shippingCode
                }).subscribe( (res:any) => {
				return resolve( this.GlobalService.httpCheckReturn(res) );
			});
		});
	}

    addShippingToOrder( salesOrderId:number, shippingId:number ): Promise<any> {
        return new Promise<ShippingOption>((resolve, reject) => {
            const url = this.AppsettingsService.getApiServerUrl() + `sales-orders/order/${salesOrderId}/shipping`;
            this.HttpClient.post(url, {                    
                    salesOrderId: salesOrderId,
                    shippingId: shippingId
            }).subscribe({
                next: (data: any) => resolve(data),
                error: (err: HttpErrorResponse) => reject(err),
            });
        });
    }

    getPersonalStats( data:GetPersonalStats ): Promise<SalesOrderPersonalStats> {
        return new Promise((resolve, reject) => {

            let params:any = { params: {} };

			const entries = Object.entries(data);
			for (const [key, value] of entries) {
				if ( value === undefined ) continue;
				
				// Convert bool to number
				if ( value === true ) {
					params.params[key] = 1
					
				} else if ( value === false ) {
					params.params[key] = 0
					
				} else {
					params.params[key] = value
				}
			}

            const url = this.AppsettingsService.getApiServerUrl() + `sales-orders/statistics/personal`;
            this.HttpClient.get(url, params).subscribe({
                next: (data: any) => resolve(data),
                error: (err: HttpErrorResponse) => reject(err),
            });
        });
    }

    monthlyCombined(): Promise<any> {
        return new Promise((resolve, reject) => {
            const url = this.AppsettingsService.getApiServerUrl() + `sales-orders/statistics/monthly-combined`;
            this.HttpClient.get(url).subscribe({
                next: (data: any) => resolve(data),
                error: (err: HttpErrorResponse) => reject(err),
            });
        });
    }

    getCompanyStats(): Promise<SalesOrderPersonalStats> {
        return new Promise((resolve, reject) => {
            const url = this.AppsettingsService.getApiServerUrl() + `sales-orders/statistics/company`;
            this.HttpClient.get(url).subscribe({
                next: (data: any) => resolve(data),
                error: (err: HttpErrorResponse) => reject(err),
            });
        });
    }

    crownedDaily(): Promise<SalesOrderPersonalStats> {
        return new Promise((resolve, reject) => {
            const url = this.AppsettingsService.getApiServerUrl() + `sales-orders/statistics/crowned/daily`;
            this.HttpClient.get(url).subscribe({
                next: (data: any) => resolve(data),
                error: (err: HttpErrorResponse) => reject(err),
            });
        });
    }

    crownedMonthly(): Promise<SalesOrderPersonalStats> {
        return new Promise((resolve, reject) => {
            const url = this.AppsettingsService.getApiServerUrl() + `sales-orders/statistics/crowned/monthly`;
            this.HttpClient.get(url).subscribe({
                next: (data: any) => resolve(data),
                error: (err: HttpErrorResponse) => reject(err),
            });
        });
    }

    crownedHalfYear(): Promise<SalesOrderPersonalStats> {
        return new Promise((resolve, reject) => {
            const url = this.AppsettingsService.getApiServerUrl() + `sales-orders/statistics/crowned/half-year`;
            this.HttpClient.get(url).subscribe({
                next: (data: any) => resolve(data),
                error: (err: HttpErrorResponse) => reject(err),
            });
        });
    }

    updateCustomer( data:UpdateCustomer ): Promise<Customer> {
        return new Promise((resolve, reject) => {
            const url = this.AppsettingsService.getApiServerUrl() + `sales-orders/customer`;
            this.HttpClient.put(url, data).subscribe({
                next: (data: any) => resolve(data),
                error: (err: HttpErrorResponse) => reject(err),
            });
        });
    }

    getOrdersByCustomerId( data:CustomerId ): Promise<Customer> {
        return new Promise((resolve, reject) => {
            const url = this.AppsettingsService.getApiServerUrl() + `sales-orders/customer`;
            this.HttpClient.get(url, {
                params: { customerId: data.customerId }
            }).subscribe({
                next: (data: any) => resolve(data),
                error: (err: HttpErrorResponse) => reject(err),
            });
        });
    }

}