import { Injectable, isDevMode } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})

export class AppsettingsService {

    getApiServerUrl() {

        // Admin endpoints only!

        const domain = this.getApiServerBaseDns();
        let port:string = ''

        if ( this.isLocalMode() ) port = ':25210';

        return `https://${domain}${port}/v1/admin/`;

    }

    getApiServerUrlNew() {

        const domain = this.getApiServerBaseDns();
        let port:string = ''

        if ( this.isLocalMode() ) port = ':25210';

        return `https://${domain}${port}/v1/`;

    }

    getApiServerUrlBase() { 

        // New code should use this address. This should be the entrypoint for all newly created code.

        const domain = this.getApiServerBaseDns();
        let port:string = ''

        if ( this.isLocalMode() ) port = ':25210';

        return `https://${domain}${port}/v1/`;
    }

    getApiServerBaseDns() {

        if ( this.isProductionMode() ) {
            return 'api.osnordic.se';

        } else if ( this.isPreviewMode() ) {
            return 'api.osn.tivala.dev';

        } else if ( this.isLocalMode() ) {
            return 'api.osn.tivala.local';

        } else {
            return false;
        }

    }

    getServerUrl() {

        if ( this.isProductionMode() ) {
            return 'https://admin.osnordic.se/';

        } else if ( this.isPreviewMode() ) {
            return 'https://admin.osn.tivala.dev';

        } else if ( this.isLocalMode() ) {
            return 'https://admin.osn.tivala.local';

        } else {
            return false;
        }

    }

    isProductionMode() {
        if ( document.location.hostname == 'admin.osnordic.se' ) {
            return true;
            
        } else {
            return false;
        }
    }

    isPreviewMode() {
        if ( document.location.hostname == 'admin.osn.tivala.dev' ) {
            return true;

        } else {
            return false;
        }
    }

    isLocalMode() {
        if ( document.location.hostname == 'admin.osn.tivala.local' ) {
            return true;

        } else {
            return false;
        }
    }

    getFrontendURL() {
        if ( this.isProductionMode() ) {
            return 'https://admin.osnordic.se';

        } else if ( this.isPreviewMode() ) {
            return 'https://osn.tivala.dev';

        } else if ( this.isLocalMode() ) {
            return 'https://osn.tivala.local';

        } else {
            return false;
        }
    }

}