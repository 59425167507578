import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable({
    providedIn: 'root'
})
export class GlobalService {

    constructor() { }

    // This makes lists render faster for some reason. Don't remove!
    promiseWait(ms: number) {
        return new Promise( async (resolve, reject) => {
            setTimeout(() => {
                return resolve(true)
            }, ms);
        });
    }

    /*
        Convert a timestamp to a full date yyyy-MM-dd HH:mm:ss
    */
    timestampToFullDate( timestamp:number ) {

        if ( timestamp === undefined || timestamp == null || timestamp < 1 ) {
            return '';
        }

        let dateTime = DateTime.fromMillis( +timestamp );
        return dateTime.toFormat('yyyy-MM-dd HH:mm:ss');
    }

    /*
        Convert a timestamp to a full date yyyy-MM-dd
    */
    timestampToDate( timestamp?:number ) {

        if ( timestamp === undefined || timestamp == null || timestamp < 1 ) {
            timestamp = Date.now();
        }

        let dateTime = DateTime.fromMillis( +timestamp );
        return dateTime.toFormat('yyyy-MM-dd');
    }
    
    /*
        Check if a pontential password conforms to our standards. Since this was not documented on when the code
        was written i have no idea what the requirements are
    */
    regExMatchPasswordComplexity( input:string ) {

        if ( input === undefined || input == null || input.length < 3 ) {
            return false;
        }

        return /(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/.test(input);
        
    }

    /*
        Check if a potential password conforms to our standards, not as strict as the above one. We require the
        potential password to be at least 8 characters and one number long
    */
    regExMatchPasswordComplexitySmall( input:string ) {

        if ( input === undefined || input == null || input.length < 3 ) {
            return false;
        }

        // At least 8 characters and one number
        return /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,}$/.test(input);
    }

    /*
        Check if a email adress conforms to email adress standards.
    */
    validateEmailAddress( email:string ) {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);

        // OLD, didnt work for .info etc
        // if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        //     return true;
        // }
        // else {
        //     return false;
        // }
    }

    /*
        Get the servers current date to whatever format desired, like 'yyyy-MM-dd'. Input like: 1464160114123
    */
    getCurrentDateWithFormat( format:string ):string {

        if ( !format ) {
            return '';
        }

        let dateTime = DateTime.fromMillis( Date.now() );
        return dateTime.toFormat( format );

    }

    /*
        Get the servers current date to whatever format desired, like 'yyyy-MM-dd'. Input like: 1464160114123
    */
    sqlDateToFullDate( mysqlDate:string ) {
        
        if ( mysqlDate === undefined || mysqlDate == null || mysqlDate.length < 1 ) {
            return mysqlDate;
        }

        let dateTime = DateTime.fromISO( mysqlDate );
        return dateTime.toFormat('yyyy-MM-dd HH:mm:ss');

    }
    
    /*
        SQL date to a regular date, like '2016-05-25T09:08:34.123'
    */
    sqlDateToDate( mysqlDate:string ) {
        
        if ( mysqlDate === undefined || mysqlDate == null || mysqlDate.length < 1 ) {
            return mysqlDate;
        }

        let dateTime = DateTime.fromISO( mysqlDate );
        return dateTime.toFormat('yyyy-MM-dd');

    }

    /*
        SQL date to a regular hours:minutes, like '13:37'
    */
    sqlDateToHoursMinutes( mysqlDate:string ) {
        
        if ( mysqlDate === undefined || mysqlDate == null || mysqlDate.length < 1 ) {
            return mysqlDate;
        }

        let dateTime = DateTime.fromISO( mysqlDate );
        return dateTime.toFormat('HH:mm');

    }

    /*
        SQL date to epoch timestamp, like '2016-05-25T09:08:34.123' and resturns like 1464160114123
    */
    sqlDateToTimestamp( mysqlDate:string ) {
        
        if ( mysqlDate === undefined || mysqlDate == null || mysqlDate.length < 1 ) {
            return mysqlDate;
        }

        return new Date( mysqlDate ).getTime();

    }

    /*
        Check if http returns returned a valid status
    */
    httpCheckReturn( res:any ) {

        console.log(res)
        if ( 
            res !== undefined           &&
            res !== null                &&
            res.status !== undefined    &&
            res.status == 200          
            ) {
            return res.data;
        } else {
            return false;
        }

    }

    /*
        Check if http returns returned a valid status
    */
    httpCheckReturnEmptyArray( res:any ) {
                
        if ( 
            res !== undefined           &&
            res !== null                &&
            res.status !== undefined    &&
            res.status == 200          
            ) {
            return res.data;
        } else {
            return [];
        }

    }

    sanitizeString( input:any )  {
        if ( input === null || input === undefined ) {
            return false;
        }
    
        input = input.toString().replace(/[^a-z0-9áéíóúñü \.,_-åäöÅÄÖ#!]/gim,"");
        return input.trim();
    }

    getParameters( data:object | undefined ) {

        // Did we get any data?
        if ( !data ) return '';

        // It must be an object
        if ( typeof data !== 'object' ) return '';

        // Check if we have any keys
        if (Object.keys(data).length === 0) return '';

        let newObject:any = {}

        for (const [key, value] of Object.entries(data)) {
            newObject[key] = value;
        }

        const params = new HttpParams({ fromObject: newObject });

        return '?' + params.toString();

    }

    getBestTimeDifference(pastDate: Date): string {
        const today = new Date();
        const differenceInMs = today.getTime() - pastDate.getTime();
    
        const msInDay = 24 * 60 * 60 * 1000;
        const msInWeek = msInDay * 7;
        const msInYear = msInDay * 365.25; // Account for leap years
        const msInMonth = msInYear / 12;
    
        const yearsAgo = Math.floor(differenceInMs / msInYear);
        const monthsAgo = Math.floor(differenceInMs / msInMonth);
        const weeksAgo = Math.floor(differenceInMs / msInWeek);
        const daysAgo = Math.floor(differenceInMs / msInDay);
    
        if (daysAgo === -1 || daysAgo === 0) {
            return `Idag`;
        } else if (yearsAgo >= 1) {
            return `${yearsAgo} år`;
        } else if (monthsAgo >= 1) {
            return monthsAgo === 1 ? `${monthsAgo} månad` : `${monthsAgo} månader`;
        } else if (weeksAgo >= 1) {
            return weeksAgo === 1 ? `${weeksAgo} vecka` : `${weeksAgo} veckor`;
        } else {
            return daysAgo === 1 ? `${daysAgo} dag` : `${daysAgo} dagar`;
        }
    }

    formatCurrency(value: number): string {
        if (value >= 1_000_000) {
            return `${(value / 1_000_000).toFixed(1)} MSEK`;
        } else if (value >= 1_000) {
            return `${(value / 1_000).toFixed(1)} KSEK`;
        } else {
            return `${value.toFixed(1)} SEK`;
        }
    }
}